import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, BrowserRouter, Route, Switch } from 'react-router-dom'
import { ResetCSS ,Button} from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
// import { Credentials, StringTranslations } from '@crowdin/crowdin-api-client'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import Swap from './Swap'
import Home from './Home'
import { LanguageContext } from '../hooks/LanguageContext'
import { TranslationsContext } from '../hooks/TranslationsContext'

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App() {
  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
          <LanguageContext.Provider
            value={{ selectedLanguage, setSelectedLanguage, translatedLanguage, setTranslatedLanguage }}
          >
            <TranslationsContext.Provider value={{ translations, setTranslations }}>
                  <Popups />
                  <Web3ReactManager>
                    <Switch>
                      <Route exact strict path='/swap' component={Swap} />
                      <Route component={Home} />
                    </Switch>
                  </Web3ReactManager>
                  <Marginer />
            </TranslationsContext.Provider>
          </LanguageContext.Provider>
      </BrowserRouter>
    </Suspense>
  )
}
