import React from 'react'
import { useWeb3React } from '@web3-react/core'
import { ButtonProps, ConnectorId, useWalletModal } from '@pancakeswap-libs/uikit'
import { Button } from 'reactstrap';
import { injected, walletconnect } from 'connectors'
import useI18n from 'hooks/useI18n'

const ConnectButton: React.FC<ButtonProps> = props => {
  const { account, activate, deactivate } = useWeb3React()

  const handleLogin = (connectorId: ConnectorId) => {
    if (connectorId === 'walletconnect') {
      return activate(walletconnect)
    }
    return activate(injected)
  }

  const { onPresentConnectModal } = useWalletModal(handleLogin, deactivate, account as string)

  return (
    <Button onClick={onPresentConnectModal} {...props}>
      Connect Wallet
    </Button>
  )
}

export default ConnectButton
