import * as React from 'react';
import { Link } from 'react-router-dom';
import {useMediaQuery} from 'react-responsive'
import NumberFormat from 'react-number-format';
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Button
} from 'reactstrap';
import ConnectButton from 'components/ConnectButton';
import { useActiveWeb3React } from 'hooks';
import { useCurrency } from 'hooks/Tokens';
import { DEFAULT_OUTPUT_CURRENCYID } from 'state/swap/hooks';
import { useCurrencyBalance } from 'state/wallet/hooks';
import Logo from '../../assets/images/logo.png';



/**
 * It is important to surround each nav item with Link if you want to use React Router
 */


interface InitialState {
  isOpen: boolean
}

const initialState:InitialState = {
    isOpen: true
}

const NavBar = () => {
    const isSMDesktop = useMediaQuery({ query: `(max-width: 1023px)` });
    const [state, setState] = React.useState(initialState)

    const { account } = useActiveWeb3React()
    const defaultCurrency = useCurrency(DEFAULT_OUTPUT_CURRENCYID)
    const defaultCurrencyBalance = useCurrencyBalance(account?? undefined, defaultCurrency?? undefined)
    console.info('default => ', defaultCurrencyBalance?.toFixed(0))

    const ontoggle = () => {
        setState({...state,
            isOpen: !state.isOpen
        })
    }

    const shorter = (str) => {
        return str?.length > 12 ? `${str.slice(0, 8)}...${str.slice(-4)}` : str
    }
    return (
      <div>
        <nav className={`navbar ${!state.isOpen&&'opened'}`}>
          <span onClick={() => ontoggle()} onKeyDown={() => ontoggle()} role="button" tabIndex={0} className={`btn-toggle ${!state.isOpen&&'opened'}`} >
            {state.isOpen?<span>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.75 5.75H19.25" stroke="#3A395E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M4.75 18.25H19.25" stroke="#3A395E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M4.75 12H19.25" stroke="#3A395E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </span>:<span>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.25 6.75L6.75 17.25" stroke="#3A395E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M6.75 6.75L17.25 17.25" stroke="#3A395E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </span>}
          </span>

          <div className="nav-links" style={{width: '100%'}}>
              <a href="https://www.thehappycoin.co/" target="_blank" rel="noreferrer"><img src={Logo} alt="Happycoin" width="166px" /></a>
            {!isSMDesktop?<div className="nav-links" style={{width: '100%'}}>
                <a href="https://www.thehappycoin.co/" target="_blank" rel="noreferrer">
                  Home
              </a>
              <Link to="/">
                  <span className="font-weight-bold">Swap</span>
              </Link>
              <a href="#help">
                  How to buy
              </a>
              <a href="#buy">
                  Exchanges
              </a>
              <div style={{flex: '1'}}>
                <a href="https://poocoin.app/tokens/0xb0b924c4a31b7d4581a7f78f57cee1e65736be1d" rel="noreferrer" target="_blank">
                  Live chart
                  <svg style={{position: 'relative', top:'-10px'}} width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.34367 8.76042V4.15625H4.7395" stroke="#3A395E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.20833 4.29175L3.65625 9.84383" stroke="#3A395E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </a>
              </div>
              {(!account? (
                <ConnectButton className="btn btn-block btn-wallet-connect btn-warning" />
              ): (
                <div className="nav-item wallet-info">
                  <div className="wallet-address">{shorter(account)}</div>
                    <div className="wallet-balance">
                      <small className="text-secondary">
                        BALANCE
                      </small>
                      <br/>
                      <p className="text-primary font-weight-bold text-balance">
                        {!defaultCurrencyBalance ? 0 : <NumberFormat value={defaultCurrencyBalance?.toFixed(0)} displayType='text' thousandSeparator />} HAPPY
                      </p>
                  </div>
                </div>
              ))}
            </div>:(!state.isOpen&&<div className="nav-links">
              <a href="https://www.thehappycoin.co/" target="_blank" rel="noreferrer">
                  Home
              </a>
              <Link to="/">
                  <span className="font-weight-bold">Swap</span>
              </Link>
              <a href="#help">
                  How to buy
              </a>
              <a href="#buy">
                  Exchanges
              </a>
              <div>
                <a href="https://poocoin.app/tokens/0xb0b924c4a31b7d4581a7f78f57cee1e65736be1d" rel="noreferrer" target="_blank">
                  Live chart
                  <svg style={{position: 'relative', top:'-10px'}} width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.34367 8.76042V4.15625H4.7395" stroke="#3A395E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.20833 4.29175L3.65625 9.84383" stroke="#3A395E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </a>
              </div>
            </div>)}
            {isSMDesktop&&!state.isOpen&&(!account? (
              <ConnectButton className="btn btn-block btn-wallet-connect btn-warning" style={{width: '100%', marginTop: isSMDesktop&&'40px'}} />
            ): (
              <div className="nav-item wallet-info">
                <div className="wallet-address">{shorter(account)}</div>
                  <div className="wallet-balance">
                    <small className="text-secondary">
                      BALANCE
                    </small>
                    <br/>
                    <p className="text-primary font-weight-bold text-balance">0000,999,999
                      {!defaultCurrencyBalance ? 0 : <NumberFormat value={defaultCurrencyBalance?.toFixed(0)} displayType='text' thousandSeparator />} HAPPY
                    </p>
                </div>
              </div>
            ))}
          </div>
        </nav>
      </div>
    );
}

export default NavBar
